<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4>Маршруты</h4>
      <b-button variant="outline-primary" @click="addClicked">Добавить</b-button>
    </div>
    <b-table
      class="mt-3"
      :fields="fields"
      :items="list"
      @row-clicked="(row) => rowClicked(row)"
      tbody-tr-class="pointer"
    >
      <template #cell(author)="{ value, item }">
        <div class="d-flex justify-content-between align-items-center flex-nowrap">
          <span class="mr-3">{{ value }}</span>
          <div class="text-nowrap">
            <b-icon
              icon="pencil"
              variant="primary"
              class="mr-3"
              @click="rowClicked(item, $event)"
            />
            <b-icon icon="trash-fill" variant="primary" @click="deleteClicked(item, $event)" />
          </div>
        </div>
      </template>
    </b-table>
    <div class="d-flex justify-content-center">
      <b-pagination class="m-auto" v-model="currentPage" :total-rows="total" per-page="25" />
    </div>
  </div>
</template>

<script>
import { Routes } from '@/request';

export default {
  name: 'Routes',
  data() {
    return {
      list: [],
      fields: [
        { key: 'title', label: 'Название' },
        { key: 'description', label: 'Описание' },
        { key: 'author', label: 'Автор', formatter: this.formatAuthor },
      ],
      currentPage: 1,
      total: 0,
    };
  },
  mounted() {
    this.loadRoutes(this.currentPage);
  },
  methods: {
    async loadRoutes(page) {
      try {
        const { data, total } = await Routes.getRoutes({ page });
        this.list = data;
        this.total = total;
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    addClicked() {
      this.$router.push({ name: 'NewRoute' });
    },
    rowClicked(row, event = undefined) {
      event?.stopPropagation();

      this.$router.push({ name: 'EditRoute', params: { id: row.id } });
    },
    async deleteClicked(row, event) {
      event?.stopPropagation();

      const userAgreed = await this.$bvModal.msgBoxConfirm(
        `Вы действительно хотите удалить маршрут: ${row.title}?`,
        {
          okTitle: 'Удалить',
          cancelTitle: 'Отмена',
          cancelVariant: 'outline-secondary',
        },
      );

      if (!userAgreed) {
        return;
      }

      try {
        this.updatingPointPosition = true;
        await Routes.deleteRoute(row.id);
        this.$bvToast.toast('Маршрут удален', { variant: 'success' });
        await this.loadRoutes();
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    formatAuthor(author) {
      return author ? `${author?.first_name} ${author?.first_name}` : 'Не указан';
    },
  },
  watch: {
    currentPage(newVal) {
      this.loadPlaces(newVal);
    },
  },
};
</script>

<style lang="scss" scoped></style>
